import { graphql, useStaticQuery } from "gatsby"

export const useSiteStrapiAbout = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiAbout {
        aboutTitle
        aboutDescription
        celebration
        ourPhotos {
          name
          id
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  `)

  return data.strapiAbout;

}