import * as React from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

gsap.registerPlugin(ScrollTrigger);

// --- ABOUT CELEBRATION COMPONENT ---
    // celebration   --> Text from strapi
    // usSmallImages --> Array of images from strapi
const AboutCelebration = (props: { celebration: string, usBigImages: any} | null) => {

    const celebrationImgs: any = props?.usBigImages && props.usBigImages.sort(((a: any, b: any) => a.name > b.name ? 1 : -1));

    const [imgCounter, setImgCounter] = useState<number>(0);
    // const [img, setImg] = useState();


    // --- START UPDATES ---
    // --- Image container animation updates 
    useEffect(() => {

        const photoUsAnim: gsap.core.Tween = gsap.to('#photoUsContainer', {
            y: '-35%',
            ease: 'power0.in',
            duration: 0.1,
        })

        ScrollTrigger.create({
            trigger: '#usContainer',
            // markers: true,
            // id: 'photo',
            start: 'top bottom',
            end: 'bottom center',
            animation: photoUsAnim,
            toggleActions: 'play none reverse reset',
            scrub: 1,
        })

    }, [])

    // --- Images updates 
    useEffect(() => {

        const photoContainer: HTMLElement | null = document.getElementById('#photoUsContainer');

        ScrollTrigger.create({
            trigger: '#usContainer',
            // markers: true,
            // id: 'images',
            start: 'top bottom',
            end: 'bottom center',
            toggleActions: 'play none reverse reset',
            scrub: 1,
            onUpdate: ({progress}) => {
                const relativeProgress = progress * 10;
                // console.log(relativeProgress)
                let counter: number = 0;
                if (relativeProgress > 0 && relativeProgress < 3) {
                    counter = 5;
                }
                if (relativeProgress > 3 && relativeProgress < 4.5) {
                    counter = 0;
                }
                if (relativeProgress > 4.5 && relativeProgress < 6) {
                    counter = 1;
                }
                if (relativeProgress > 6 && relativeProgress < 7.5) {
                    counter = 2;
                }
                if (relativeProgress > 7.5 && relativeProgress < 9) {
                    counter = 3;
                }
                if (relativeProgress > 9 && relativeProgress < 10) {
                    counter = 4;
                }
                // if (relativeProgress > 8 && relativeProgress < 9.5) {
                //     counter = 4;
                // }
                // if (relativeProgress > 9.5 && relativeProgress < 10) {
                //     counter = 5;
                // }

                setImgCounter(counter);

            }
        })

    }, [imgCounter])
    // --- END UPDATES ---


    // --- START FUNCTIONS ---
    // Render updated image function from counter State
    const updateImage = (counter: number) => {

        let image: IGatsbyImageData = celebrationImgs && getImage(celebrationImgs[counter].localFile);

        return (
            <>
                <GatsbyImage image={image} alt={celebrationImgs && celebrationImgs[counter].caption} className='h-full'/>
            </>
        )
    }
    // --- END FUNCTIONS ---

    
    return (
        <>
            <div id='usContainer' className='h-screen lg:pt-52 lg:min-h-screen'>
                <div className="flex items-center justify-center h-full">
                    <div className='relative flex w-full h-full lg:w-3/4'>
                        <div id="photoUsContainer" className='absolute flex flex-col justify-end w-full h-full'>
                            <div className='flex items-center justify-center w-2/3 h-1/3 md:h-1/2 lg:w-3/5 xl:w-2/5 lg:h-4/5'>
                                {
                                    updateImage(imgCounter)
                                }
                            </div>
                            {/* <div className='hidden pt-5 text-2xl lg:flex'>{imgName && imgName}</div> */}
                        </div>
                        <div className='z-10 flex items-center justify-end w-full h-full text-4xl leading-none md:text-5xl lg:text-6xl xl:text-8xl'>
                            <div className='flex text-right uppercase w-72 sm:w-full md:w-4/5 lg:w-5/6'>
                                {props?.celebration}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCelebration;