import * as React from 'react';
import Footer from '../components/footer';
import Header from "../components/header";
import { SEO } from '../components/seo';
import AboutImages from './about-components/about-images';
import { useSiteStrapiAbout } from '../hooks/site-strapi-about';
import DefaultAppView from '../app-view/default-app-view';
import AboutCelebration from './about-components/about-celebration';
import AboutDescription from './about-components/about-description';
import AboutPartership from './about-components/about-partnership';

const About = () => {

    // --- START QUERYS ---
    const strapiQuery: any = useSiteStrapiAbout();

    // const strapiQuery = useStaticQuery(query);
    const usBigImages: any = strapiQuery.ourPhotos.filter((el: any) => el.name && (el.name as string).includes('big') || null);
    const usSmallImages: any = strapiQuery.ourPhotos.filter((el: any) => el.name && (el.name as string).includes('small') || null);

    // const _partners: any[] = strapiQuery.partners.map((el: any) => {
    //     return {
    //         partnerTitle: el.partnerTitle,
    //         partnerDescription: el.partnerDescription
    //     }
    // }).sort((a: any, b: any) => a.partnerTitle > b.partnerTitle ? 1 : -1);
    // --- END QUERYS ---

    return (
        <DefaultAppView>
            <Header currentPageTheme='dark'/>
            <div className='px-5 pt-20 bg-black md:px-16 sm:px-10 lg:px-36 lg:pt-40 text-cardinal-white'>
                <AboutDescription aboutTitle={strapiQuery?.aboutTitle} aboutDescription={strapiQuery?.aboutDescription}/>
            </div>
            {/* <AboutImages usBigImages={usBigImages}/> */}
            <div className='px-5 bg-black md:px-16 sm:px-10 lg:px-36 text-cardinal-white'>
                {/* <AboutPartership partnershipTitle={data.partnership.partnershipTitle} partners={_partners}/> */}
                <AboutCelebration celebration={strapiQuery?.celebration} usBigImages={usBigImages}/>
                <div className='bg-black lg:h-96'/>
            </div>
            <Footer/>
        </DefaultAppView>
    )
}

export default About;

export const Head = () => (
    <SEO title='Cardinal - Supersecret About'/>
)